import { Controller } from "@hotwired/stimulus"

const FORM_URL = "https://formspree.io/f/mjvddvwq"
const ERROR_MESSAGES = {
  incompleteForm: "Veuillez completer le formulaire dans son entiereté.",
  invalidEmail: "Veuillez utiliser une adresse email valide.",
  // eslint-disable-next-line max-len
  submitError: "Une erreur est intevenu lors de l'envoi de votre formulaire. Veuillez réessayer ou contactez nous via notre Linkedin."
}

// Connects to data-controller="contact-form"
export default class extends Controller {
  static targets = ["error", "success"]

  handleSubmit(event) {
    event.preventDefault()
    const formData = new FormData(event.target)

    if (this.#validateFormData(formData)) this.#sendMail(formData)
  }

  #buildParams(data) {
    const params = new FormData()
    const message = `${data.get("message")}\n\nMessage sent by ${data.get("name")}}`

    params.append("email", data.get("email"))
    params.append("message", message)

    return params
  }

  #handleFormSuccess() {
    this.errorTarget.innerText = ""
    this.successTarget.innerText = "Votre email a bien été envoyé. Nous vous répondrons dans les plus bref delais."
    this.element.reset()
  }

  #validateEmail(email) {
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)
  }

  #validateFormData(data) {
    if (!this.#validatePresence([...data.values()])) {
      this.errorTarget.innerText = ERROR_MESSAGES.incompleteForm
      return false
    }

    if (!this.#validateEmail(data.get("email"))) {
      this.errorTarget.innerText = ERROR_MESSAGES.invalidEmail
      return false
    }

    return true
  }

  #validatePresence(values) {
    return !values.includes("")
  }

  async #handleFormErrors(res) {
    const data = await res.json()

    if (data.errors) this.errorTarget.innerHTML = data.errors.map(e => e.message).join(", ")
    else this.errorTarget.innerHTML = ERROR_MESSAGES.submitError
  }

  async #sendMail(data) {
    try {
      const res = await fetch(FORM_URL, {
        method: "POST",
        body: this.#buildParams(data),
        headers: { Accept: "application/json" }
      })

      if (res.ok) this.#handleFormSuccess()
      else this.#handleFormErrors(res)
    } catch {
      this.errorTarget.innerHTML = ERROR_MESSAGES.submitError
    }
  }
}
