import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="menu"
export default class extends Controller {
  static targets = ["menu"]

  scrollToTarget(event) {
    const target = document.querySelector(`#${event.currentTarget.dataset.target}`)
    target.scrollIntoView({ behavior: "smooth" })
    this.menuTarget.classList.remove("show")
  }

  toggleMenu() {
    this.menuTarget.classList.toggle("show")
  }
}
